import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { AppMainComponent } from './layout/component/app.main.component';
import { AppMainEssComponent } from './layout/component-ess/app.main.component';
import { PageNotFoundComponent } from './layout/page-not-found/page-not-found.component';
import { PageForbiddenComponent } from './layout/page-forbidden/page-forbidden.component';
import { LoginComponent } from './layout/login/login.component';
import { AuthGuard } from './core/auth.guard';
import { DashboardComponent } from './core/dashboard/dashboard.component';
import { DashboardEssComponent } from './core/dashboard-ess/dashboard.component';
import { PermisionGuard } from './core/permision.guard';
import { RedirectComponent } from './layout/redirect/redirect.component';
import { UserCreatePassword } from '@workbench/user-2/pages/create-password/create-password.component';
import { ResetPasswordComponent } from '@workbench/user-2/pages/reset-password/reset-password.component';
import { ForgotPasswordComponent } from '@layout/forgot-password/forgot-password.component';
import { PrivacyPoliciesComponent } from '@layout/privacy-policies/privacy-policies.component';

const web: string = localStorage.getItem('web') ?? 'admin';

@NgModule({
    imports: [
        RouterModule.forRoot(
            [
                {
                    path: 'dashboard',
                    component: AppMainComponent,
                    children: [{ path: '', component: DashboardComponent }],
                    canActivate: [AuthGuard],
                },
                {
                    path: 'dashboard-ess',
                    component: AppMainEssComponent,
                    children: [{ path: '', component: DashboardEssComponent }],
                    canActivate: [AuthGuard],
                },
                {
                    path: 'login',
                    component: LoginComponent,
                    canActivate: [PermisionGuard],
                },
                {
                    path: 'forgot-password',
                    component: ForgotPasswordComponent,
                    canActivate: [PermisionGuard],
                },
                {
                    path: 'create-password',
                    component: UserCreatePassword,
                },
                {
                    path: 'reset-password',
                    component: ResetPasswordComponent,
                },
                {
                    path: 'privacy-policy',
                    component: PrivacyPoliciesComponent,
                    canActivate: [PermisionGuard],
                },
                {
                    path: 'redirect',
                    component: RedirectComponent,
                },
                {
                    path: '404',
                    component: PageNotFoundComponent,
                },
                {
                    path: '403',
                    component: PageForbiddenComponent,
                },
                {
                    path: 'util',
                    component: AppMainComponent,
                    loadChildren: () =>
                        import('./util/util.module').then((m) => m.UtilModule),
                    canActivate: [AuthGuard],
                },
                {
                    path: 'showcase',
                    component: AppMainComponent,
                    loadChildren: () =>
                        import('./showcase/showcase.module').then(
                            (m) => m.ShowcaseModule
                        ),
                    canActivate: [AuthGuard],
                },
                {
                    path: 'admin',
                    pathMatch: 'prefix',
                    redirectTo: 'admin/dashboard',
                },
                {
                    path: '',
                    pathMatch: 'prefix',
                    redirectTo: `${web}/dashboard`,
                },
                {
                    path: 'ess',
                    pathMatch: 'prefix',
                    redirectTo: 'ess/dashboard',
                },
                {
                    path: 'mss',
                    pathMatch: 'prefix',
                    redirectTo: 'mss/dashboard',
                },
                {
                    path: 'ess',
                    canActivate: [AuthGuard],
                    component: AppMainEssComponent,
                    loadChildren: () =>
                        import('projects/ess/app.module').then(
                            (m) => m.ESSSharedModule
                        ),
                },
                {
                    path: 'mss',
                    canActivate: [AuthGuard],
                    component: AppMainEssComponent,
                    loadChildren: () =>
                        import('projects/mss/app.module').then(
                            (m) => m.MSSSharedModule
                        ),
                },
                {
                    path: 'admin',
                    canActivate: [AuthGuard],
                    component: AppMainComponent,
                    loadChildren: () =>
                        import('projects/admin/app.module').then(
                            (m) => m.AdminSharedModule
                        ),
                },
                { path: '**', redirectTo: '404' },
            ],
            {
                scrollPositionRestoration: 'enabled',
                canceledNavigationResolution: 'computed',
            }
        ),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
